import { api } from "@src/app/api";
import {
  VerificationMethod,
  VerificationPreferenceDetailsProps,
  VerificationPreferences,
} from "@src/lib/interface";
import { useEffect, useState } from "react";

const plural = (count?: number) => (count && count > 1 ? "s" : "");
const toSentenceCase = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
const formatMultipleItems = (strs: string[]): string => {
  if (!strs.length) {
    return "";
  }
  const last = strs.pop();
  if (!strs.length) {
    return last!;
  }
  return `${strs.join(", ")} and ${last}`;
};

const VerificationPreferenceDetails = ({
  facilityId,
  verificationPreference,
  timeSheetExists,
}: VerificationPreferenceDetailsProps) => {
  const [nonAvailableDays, setNonAvailableDays] = useState([
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ]);
  const [facility, setFacility] = useState<VerificationPreferences>();

  useEffect(() => {
    if (verificationPreference?.verificationMethod) {
      setFacility(verificationPreference);
    } else {
      // Always request the updated preferences
      // since they're now stored in a separate location
      if (facilityId) {
        (async function getFacility() {
          const { data } = await api.facility.fetchFacilityDetails(facilityId);
          setFacility(data);
        })();
      }
    }
  }, [facilityId, verificationPreference]);

  useEffect(() => {
    // If the facility's prefs couldn't be retrieved
    // do nothing
    if (!facility?.name) {
      return;
    }
    // If facility has no contacts
    if (facility?.contacts?.length === 0) {
      // We will default to contacting them every day
      setNonAvailableDays([]);
      return;
    }
    const nonAvailableDaysSet = new Set(nonAvailableDays);
    facility?.contacts?.forEach(({ archived, availability }) => {
      if (archived) {
        return;
      }
      availability?.forEach(({ day }) => nonAvailableDaysSet.delete(day));
    });
    setNonAvailableDays(Array.from(nonAvailableDaysSet));
  }, [facility]); // eslint-disable-line react-hooks/exhaustive-deps

  const attemptsPerDay = facility?.maxOutreachAttemptsPerDay;
  const numAvailableDays = 7 - nonAvailableDays.length;

  return !facility ? null : (
    <>
      {facility.verificationMethod.name === VerificationMethod.TIMESHEET && (
        <>
          {timeSheetExists ? (
            <p>
              We verify shifts with {facility.name} shortly after timesheet is reviewed and the
              shift is verified!
            </p>
          ) : (
            <p>We verify shifts with {facility.name} shortly after timesheets are uploaded.</p>
          )}
        </>
      )}
      {facility.verificationMethod.name === VerificationMethod.OUTBOUND && (
        <p>
          <span>
            We attempt to verify shifts {attemptsPerDay} time
            {plural(attemptsPerDay)} a day, {numAvailableDays} day
            {plural(numAvailableDays)} a week with {facility?.name}.
          </span>{" "}
          {!!nonAvailableDays.length && (
            <span>
              They are unavailable on {formatMultipleItems(nonAvailableDays.map(toSentenceCase))}.
            </span>
          )}
        </p>
      )}
    </>
  );
};
export { VerificationPreferenceDetails };
