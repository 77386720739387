import "./style.scss";
import { USER_EVENTS } from "@constants/userEvent";
import { IonActionSheet, IonAlert } from "@ionic/react";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { SUPPORT_LINKS as DEFAULT_SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { Shift } from "@src/lib/interface";
import { setTimeSheetAvailability } from "@store/ongoingShifts/apiV2";
import { Dispatch, SetStateAction, useState } from "react";

import { CancelShiftModal } from "../cancelShiftModal";

const reasons = {
  noPhoto: {
    title: "Thanks for letting us know!",
    message:
      "In order to get paid quickly, we require an uploaded timesheet. Without a timesheet, it can take up to a week to verify your shift.",
    text: "I forgot to take a photo",
    event: USER_EVENTS.TIMESHEET_PHOTO_FORGOTTEN,
  },
  otherWay: {
    title: "Thank you!",
    message:
      "We’ll take a look for it. Uploading your timesheet in the app is the quickest way to get paid.",
    text: "I sent it to Clipboard another way",
    event: USER_EVENTS.TIMECARD_SENT_ANOTHER_WAY,
  },
  noShift: {
    title: "Thank you!",
    message:
      "We’ll take a look for it. Uploading your timesheet in the app is the quickest way to get paid.",
    text: "I didn’t work this shift",
    event: USER_EVENTS.SHIFT_NOT_ATTENDED,
  },
};

interface NoTimeSheetOptionsProps {
  shift?: Shift;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  cancel?: Function;
  setCanShowUploadButton?: Dispatch<SetStateAction<boolean>>;
}

const NoTimeSheetOptions = ({
  visible,
  setVisible,
  cancel,
  shift,
  setCanShowUploadButton,
}: NoTimeSheetOptionsProps) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openNCNS, setOpenNCNS] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const agent = useDefinedAgent();
  const { [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks } = useCbhFlags();
  const cancelModal = () => {
    setVisible(false);
    cancel?.();
  };
  const setTimesheetUnavailableData = async (reason) => {
    const query = {
      shiftId: shift?._id,
      reason: reason,
      region: shift?.facility?.fullAddress?.metropolitanStatisticalArea,
      agentName: agent.name,
    };
    await setTimeSheetAvailability(query as Parameters<typeof setTimeSheetAvailability>[0]);
    setCanShowUploadButton?.(false);
  };
  const setReasonsAndAlertValues = async (type) => {
    logEvent(reasons[type].event);
    await setTimesheetUnavailableData(reasons[type].event);
    if (type === "noPhoto") {
      window.open(supportLinks?.TIMESHEET_SIGNATURES || DEFAULT_SUPPORT_LINKS.TIMESHEET_SIGNATURES);
    }

    if (type !== "noShift") {
      setTitle(reasons[type].title);
      setMessage(reasons[type].message);
      setOpenAlert(true);
    } else {
      cancelModal();
    }
  };
  const shiftMarkedNCNS = () => {
    setOpenNCNS(false);
    setReasonsAndAlertValues("noShift");
  };

  return (
    <>
      <IonActionSheet
        mode="ios"
        isOpen={visible}
        onDidDismiss={cancelModal}
        cssClass="my-custom-class"
        header={"Why don’t you have a timesheet?"}
        buttons={[
          {
            text: reasons.noPhoto.text,
            role: "noPhoto",
            cssClass: "primary",
            handler: () => {
              setReasonsAndAlertValues("noPhoto");
            },
          },
          {
            text: reasons.otherWay.text,
            role: "otherWay",
            cssClass: "secondary",
            handler: () => {
              setReasonsAndAlertValues("otherWay");
            },
          },
          {
            text: reasons.noShift.text,
            role: "noShift",
            cssClass: "secondary",
            handler: () => {
              setOpenNCNS(true);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ]}
      ></IonActionSheet>
      <IonAlert
        mode="ios"
        isOpen={openAlert}
        onDidDismiss={() => cancel}
        cssClass="my-custom-class"
        header={title}
        message={message}
        buttons={[
          {
            text: "OK",
            role: "ok",
            cssClass: "primary",
            handler: () => {
              cancel?.();
            },
          },
        ]}
      />
      {openNCNS && (
        <CancelShiftModal
          isPendingVerification={true}
          shift={shift as Shift}
          onDismiss={() => setOpenNCNS(false)}
          onSuccess={shiftMarkedNCNS}
          onlyModal={true}
        />
      )}
    </>
  );
};
export { NoTimeSheetOptions };
